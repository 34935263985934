import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { db } from "../App";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import emailjs from "@emailjs/browser";
const BookRoom = () => {
  var roomName;
  const navigate = useNavigate();
  const params = useParams();
  const [events, setEvents] = useState([]);
  const [name, setName] = useState("");
  const [date, setDate] = useState(0);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [blocked, setBlocked] = useState([]);
  const [error, setError] = useState("");
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  const sendConfirmationEmail = (e) => {
    emailjs.send("service_ctl7o3t", "template_m5qo43m1", e , "INBhCaabMmHG27i_D").then(
      (result) => {
        console.log(result,"Email sent.");
      },
      (error) => {
        console.log(error,"Failed to send.");
      }
    );
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    if (success) {
      setName("");
      setEndTime("");
      setError("");
      setStartTime("");
      setSuccess("");
      setLoading(false);
      navigate("");
    }
    listBlocked();
  }, [dispatch, success, userInfo, navigate]);
  const listDateEvents = async (date) => {
    try {
      setLoading(true);
      let data = [];
      const q = query(
        collection(db, "events"),
        where("room", "==", params.id),
        where("date", "==", date)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
      console.log(data);
      setEvents(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
    }
  };
  const listBlocked = async () => {
    try {
      setLoading(true);
      const docSnap = await getDoc(doc(db, "blocked", "5FmCJ3awBPwLzWjXzyZS"));
      setBlocked(docSnap.data().dates);
      setLoading(false);
    } catch (error) {
      setError(error.message);
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(blocked)
    if (blocked.includes(date)) {
      setLoading(false);
      document.getElementById("failureBlocked").hidden = false;
      return;
    }
    let mStartTime = moment(startTime, "hh:mm").format("HH:mm");
    let mEndTime = moment(endTime, "hh:mm").format("HH:mm");
    for (let i = 0; i < events.length; i++) {
      if (
        moment(mStartTime, "HH:mm").isBetween(
          moment(events[i].startTime, "HH:mm"),
          moment(events[i].endTime, "HH:mm"),
          undefined,
          '[]'
        )
      ) {
        setLoading(false);
        document.getElementById("failureOverlap").hidden = false;
        return;
      } else if (
        moment(mEndTime, "HH:mm").isBetween(
          moment(events[i].startTime, "HH:mm"),
          moment(events[i].endTime, "HH:mm"),
          undefined,
          '[]'
        )
      ) {
        setLoading(false);
        document.getElementById("failureOverlap").hidden = false;
        return;
      } else if (
        moment(events[i].startTime, "HH:mm").isBetween(
          moment(mStartTime, "HH:mm"),
          moment(mEndTime, "HH:mm"),
          undefined,
          '[]'
        )
      ) {
        setLoading(false);
        document.getElementById("failureOverlap").hidden = false;
        return;
      } else if (
        moment(events[i].endTime, "HH:mm").isBetween(
          moment(mStartTime, "HH:mm"),
          moment(mEndTime, "HH:mm"),
          undefined,
          '[]'
        )
      ) {
        setLoading(false);
        document.getElementById("failureOverlap").hidden = false;
        return;
      } else {
        continue;
      }

        /*setTimeout(() => {
          window.location.reload();
        }, "3000")*/
    }

    let id = userInfo.email + Date.now();
    setDoc(doc(db, "events", id), {
      id: id,
      title: name,
      date,
      startTime: moment(startTime, "hh:mm").format("HH:mm"),
      endTime: moment(endTime, "hh:mm").format("HH:mm"),
      room: params.id,
      accepted: "accepted", // change this to pending to use middle-man step
      email: userInfo.email,
      name: userInfo.name,
    })
      .then((e) => {
        setLoading(false);
        setSuccess(true);
        document.getElementById("success").hidden = false;
        document.getElementById("failureOverlap").hidden = true;
        document.getElementById("failureBlocked").hidden = true;
        if (params.id == 1) var room = "Great Room";
        if (params.id == 2) room = "Interview Room";
        if (params.id == 3) room = "Room 800";
        if (params.id == 4) room = "Room 114";
        if (params.id == 5) room = "Room 406";
        if (params.id == 6) room = "Room 101";
        if (params.id == 7) room = "IMH";
        sendConfirmationEmail({
          to_email: userInfo.email,
          title: name,
          booked_by: userInfo.name,
          date: date,
          startTime: startTime,
          endTime: endTime,
          room: room,
        });
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  return (
    <>
      <FormContainer>
        {(() => {
          if (params.id == 1) roomName = "Great Room";
          if (params.id == 2) roomName = "Interview";
          if (params.id == 3) roomName = "Room 800";
          if (params.id == 4) roomName = "Room 114";
          if (params.id == 5) roomName = "Room 406";
          if (params.id == 6) roomName = "Room 101";
          if (params.id == 7) roomName = "IMH";
        })()}
        <h1>Book {roomName}</h1>
        <div id="success" class="successAlert" hidden>
          <span class="closebtn" onClick={() => {document.getElementById('success').hidden = true;}}>&times;</span> 
          Reservation has been booked successfully.
        </div>
        <div id="failureBlocked" class="errorAlert" hidden>
          <span class="closebtn" onClick={() => {document.getElementById('failureBlocked').hidden = true;}}>&times;</span> 
          This date has been blocked for this room.
        </div>
        <div id="failureOverlap" class="errorAlert" hidden>
          <span class="closebtn" onClick={() => {document.getElementById('failureOverlap').hidden = true;}}>&times;</span> 
          Reservation time overlaps with another meeting. Please adjust the time.
        </div>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="name"
                placeholder="Enter Event Title"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <br/>
            <Form.Group controlId="date">
              <Form.Label>Date</Form.Label>
              <Form.Control
                required
                type="date"
                min={new Date().toISOString().split("T")[0]}
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                  listDateEvents(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>
            <br/>
            <Form.Group controlId="starttime">
              <Form.Label>Start Time</Form.Label>
              <Form.Control
                required
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <br/>
            <Form.Group controlId="endtime">
              <Form.Label>End Time</Form.Label>
              <Form.Control
                required
                type="time"
                placeholder="Enter End Time"
                value={endTime}
                min={startTime}
                onChange={(e) => setEndTime(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <br/>
            <Button type="submit" variant="primary">
              Create Event
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default BookRoom;
