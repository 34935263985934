import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { Table, Button, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { db } from "../App";
import Loader from "../components/Loader";
import emailjs from "@emailjs/browser";
import Message from "../components/Message";
import Rooms from "./Rooms";
import { useParams } from "react-router-dom";
import Product from "../components/Product";
import { getDropdownMenuPlacement } from "react-bootstrap/esm/DropdownMenu";
const EventsList = ({ events, title }) => {
  var roomName;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [loading, setLoading] = useState(false);
  const sendConfirmationEmail = (e) => {
    emailjs.send("service_ctl7o3t", "template_m5qo43m1", e , "INBhCaabMmHG27i_D").then(
      (result) => {
        console.log(result,"Email sent.");
      },
      (error) => {
        console.log(error,"Failed to send.");
      }
    );
  };
  const sendCancellationEmail = (e) => {
    emailjs.send("service_ctl7o3t", "template_qqyf4m7", e , "INBhCaabMmHG27i_D").then(
      (result) => {
        console.log(result,"Email sent.");
      },
      (error) => {
        console.log(error,"Failed to send.");
      }
    );
  };
  const acceptRequest = (event) => {
    setLoading(true);
    const eventRef = doc(db, "events", event.id);
    updateDoc(eventRef, {
      accepted: "accepted",
    })
      .then(() => {
        setLoading(false);
        if (event.room == 1) var room = "Great Room";
        if (event.room == 2) room = "Interview Room";
        if (event.room == 3) room = "Room 800";
        if (event.room == 4) room = "Room 114";
        if (event.room == 5) room = "Room 406";
        if (event.room == 6) room = "Room 101";
        if (event.room == 7) room = "IMH";
        sendConfirmationEmail({
          to_email: event.email,
          title: event.title,
          booked_by: event.name,
          date: event.date,
          startTime: event.startTime,
          endTime: event.endTime,
          room: room,
        });
        document.getElementById("success").hidden = false;
        setTimeout(() => {
          window.location.reload();
        }, "3000")
      })
      .catch((e) => {
        setLoading(false);
        console.log(e)
        alert(e.message);
      });
  };
  const deleteRequest = (product) => {
    setLoading(true);
    if (product.room == 1) var room = "Great Room";
    if (product.room == 2) room = "Interview Room";
    if (product.room == 3) room = "Room 800";
    if (product.room == 4) room = "Room 114";
    if (product.room == 5) room = "Room 406";
    if (product.room == 6) room = "Room 101";
    if (product.room == 7) room = "IMH";
    deleteDoc(doc(db, "events", product.id))
      .then((e) => {
        setLoading(false);
        sendCancellationEmail({
          to_email: product.email,
          title: product.title,
          booked_by: product.name,
          date: product.date,
          startTime: product.startTime,
          endTime: product.endTime,
          room: room,
        });
        document.getElementById("successDelete").hidden = false;
        setTimeout(() => {
          window.location.reload();
        }, "3000")
      })
      .catch((err) => {
        setLoading(false);
        alert(err.message);
      });
  };
  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h1>{title}</h1>
          <div id="successDelete" class="successAlert" hidden>
            <span class="closebtn" onClick={() => {document.getElementById('successDelete').hidden = true;}}>&times;</span> 
            The reservation has been deleted. Reloading page...
          </div>
        </Col>
      </Row>

      <>
        {loading && <Loader />}
        <Table striped bordered hover responsive className="table-sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>TITLE</th>
              <th>DATE</th>
              <th>START TIME</th>
              <th>END TIME</th>
              <th>ROOM</th>
              <th>REQUESTED BY</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {events?.map((product, idx) => (
              <tr key={idx}>
                {(() => {
                  if (product.room == 1) roomName = "Great Room";
                  if (product.room == 2) roomName = "Interview";
                  if (product.room == 3) roomName = "Room 800";
                  if (product.room == 4) roomName = "Room 114";
                  if (product.room == 5) roomName = "Room 406";
                  if (product.room == 6) roomName = "Room 101";
                  if (product.room == 7) roomName = "IMH";
                })()}
                <td>{idx + 1}</td>
                <td>{product.title}</td>
                <td>{product.date}</td>
                <td>{product.startTime}</td>
                <td>{product.endTime}</td>
                <td>{roomName}</td>
                <td>{product.name}</td>
                {title === "Pending" && userInfo?.isAdmin === true && (
                  <td>
                  <div id="success" class="successAlert" hidden>
                    <span class="closebtn" onClick={() => {document.getElementById('success').hidden = true;}}>&times;</span> 
                    The reservation has been accepted.
                  </div>
                    <Button
                      variant="success"
                      onClick={() => acceptRequest(product)}
                      className="btn-sm"
                    >
                      Accept Request
                    </Button>
                  </td>
                )}
                {userInfo?.isAdmin && (
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => deleteRequest(product)}
                      className="btn-sm"
                    >
                      Delete Request
                    </Button>
                  </td>
                )}
                {userInfo?.name == product.name && (
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => deleteRequest(product)}
                      className="btn-sm"
                    >
                      Delete Request
                    </Button>
                  </td>
                )}
                {userInfo?.name != product.name && !userInfo?.isAdmin && (
                  <td>
                    No permission.
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    </>
  );
};

export default EventsList;